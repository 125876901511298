<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Bezeichnung</label>
          <input
            type="text"
            v-model="requirement.name"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.requirement.name.$error }"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Beschreibung</label>
          <textarea
            v-model="requirement.description"
            rows="7"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { createOne, updateOne } from '@/services/requirement'

const tempRequirement = {
  name: '',
  description: '',
}

export default {
  name: 'new-requirement',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      requirement: { ...tempRequirement },
    }
  },
  validations() {
    return {
      requirement: {
        name: {
          required,
        },
        // description:{
        //   required,
        // },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.requirement = { ...this.payload }
    },
    save() {
      this.$v.requirement.$touch()

      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        this.requirement['roleprofile'] = this.parent_id
        if (this.requirement.id) {
          updateOne(this.requirement).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        } else {
          createOne(this.requirement).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        }
      }
    },
  },
  watch: {},
}
</script>
